.asset-from-input {
    background: transparent;
    text-align: center;
    border-bottom: solid;
    font-size: 40px;
    /* padding: 0px; */
    outline: none;
    width: 140px;
}

.input-error {
    background: transparent;
    text-align: center;
    border-bottom: solid;
    font-size: 40px;
    /* padding: 0px; */
    outline: none;
    width: 140px;
    color: #EF3456;
}

.arrow-swap {
}

.pair-search-input{
    background: transparent;
    border-bottom: solid;
    outline: none;
    text-align: center;
    font-size: 24px;
    min-width: 200px;
}
